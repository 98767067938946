import React from 'react';

import FunIcon1 from '../../assets/images/funfacts/fun-icon1.png'
import FunIcon2 from '../../assets/images/funfacts/fun-icon2.png'
import FunIcon3 from '../../assets/images/funfacts/fun-icon3.png'
import FunIcon4 from '../../assets/images/funfacts/fun-icon4.png'
import FunIcon5 from '../../assets/images/funfacts/fun-icon5.png'

const Funfacts = () => {
    return (
        <div className="funfacts-area bg-image pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-sm-3 col-6 col-md-3">
                        <div className="single-funfacts-item">
                            <div className="icon">
                                <img src={FunIcon2} alt="FunIcon" />
                            </div>
                            <h3>Reten Suscriptores</h3>
                            <p>Incrementa la Facturación</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-6 col-md-3">
                        <div className="single-funfacts-item">
                            <div className="icon">
                                <img src={FunIcon4} alt="FunIcon" />
                            </div>
                            <h3>Aumenta tu nivel de Satisfacción</h3>
                            <p>Obtén más Recomendaciones</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-6 col-md-3">
                        <div className="single-funfacts-item">
                            <div className="icon">
                                <img src={FunIcon3} alt="FunIcon" />
                            </div>
                            <h3>Mejora la Experiencia</h3>
                            <p>Los Clientes Felices se quedan</p>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default Funfacts;
