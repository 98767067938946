import React from 'react';
import Navbar from "../components/App/YftelNavbar2"
import Footer from "../components/App/YftelFooter"
import Layout from "../components/App/YftelLayout"
import MainBanner from '../components/YftelRetencion/MainBanner'
import FeaturedServices from '../components/YftelRetencion/FeaturedServices'
import WhyChooseUs from '../components/YftelRetencion/WhyChooseUs'
import WhatWeDo from '../components/YftelRetencion/WhatWeDo'
import Funfacts from '../components/YftelRetencion/Funfacts'
import Services from '../components/YftelRetencion/Services'
import Testimonials from '../components/YftelRetencion/Testimonials'
import WeServe from '../components/YftelRetencion/WeServe'
import TeamMembers from '../components/YftelRetencion/TeamMembers'
import CaseStudy from '../components/YftelRetencion/CaseStudy'
import BlogPost from '../components/YftelRetencion/BlogPost'
import StartProject from '../components/Common/YftelStartProject';

const Home = () => {
    return (
        <Layout>
            <Navbar />
            <MainBanner />
            <Funfacts />
            <Services />
            <StartProject />
            <WhyChooseUs />
            <Testimonials />
            <FeaturedServices />
            <WhatWeDo />
            <TeamMembers />
            <CaseStudy />
        {/* <BlogPost /> */}
            <Footer />
        </Layout>
    );
}

export default Home;
